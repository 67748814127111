@import '../../styles/_mixins';
@import '../../styles/_variables';

/*Desktop View*/
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    height: 80px;
    max-width: 1200px;

    & a {
      text-decoration: none;
    }

    &-logo {
      font-weight: bold;
      color: inherit;
      border: 2px solid $color-primary-dark;
      border-radius: 50%;
      padding: 0.3rem 0.5rem;
      transition: 0.3s;

      &:hover {
        background-color: $color-primary-dark;
        color: $color-primary;
      }
    }

    &-logo-nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-logo-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            width: 45px;
            height: 45px;
          }
      }

      &-nav-options {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        justify-content: center;
        align-items: center;

        .option {
            margin-right: 1em;
            
            & a {
                text-decoration: none;
                font-weight: bold;
                color: inherit;

                @include pointer;

                &:hover {
                  color: $color-primary-light;
                }
            }
        }

        .resume {
          border: 1px solid $color-primary-dark;
          padding: 0.5em;
          transition: 0.3s;

          &:hover {
            background-color: $color-primary-dark;
            color: $color-primary-light;
          }
        }
      }

      &-mobile-menu {
        display: none;
      }

      .theme {
        cursor: pointer;
      }

  }

  @media (max-width: 700px) {
    /*Mobile View */
    .header {
      padding: 0px 10px;

      .logo {
        width: 45px;
        height: 45px;
      }

      &-nav-options {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 360px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 0;
        transition: all 0.5s ease;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 0px; 

        &.active {
            background: $color-primary;
            left: 0;
            opacity: 1;
            transition: all 0.5s ease;
            z-index: 1;
            align-content: center;
            padding-left: 0px;
          }

          .option {
            height: 10vw;
            padding: 10px 0px;
            margin-right: 0;
          }

          .resume {
            border: 1px solid $color-primary-dark;
            padding: 0.5em;
            padding-bottom: 0;
            margin-bottom: 2em;
          }
      }

      .header-mobile-menu {
        display: block;

        .menu-icon {
            width: 45px;
            height: 45px;
          }
      }
    }
  }

  .dark-header{
    .header-logo {
      border: 2px solid $color-primary;
      border-radius: 50%;

      &:hover {
        background-color: $color-primary;
        color: $color-primary-dark;
      }
    }

    .active {
      background: #353535;
      box-shadow: 6px 5px 5px 5px rgba(0,0,0,0.11)
    }

    .resume {
      border: 1px solid $color-primary;

    }
  }

  