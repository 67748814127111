@import '../styles/_mixins';

@-webkit-keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

.Home {
    @include center-vertically;
    min-height: 100vh;
    position: relative;
    &-icons {
        @include center;
        @include pointer;
        margin-top: 1em;
        margin-bottom: 2em;
    }

    h1 {
        width: 50%;
        margin-bottom: 1em;
        margin-left: auto;
        margin-right: auto;
    }

    &-down {
        display: flex;
        justify-content: center;
        position: relative;
        bottom: 10%;
        -webkit-animation: action 0.5s infinite  alternate;
        animation: action 0.5s infinite  alternate;
    }
}

@media only screen and (max-width: 500px) {
    .Home {
        h1 {
            width: 95%;
            margin-top: -4em;
        }

        &-down {
            bottom: 20%;
        }
    }
  }

  @media only screen and (max-width: 360px) {
    .Home {
        h1 {
            margin-top: 0;
        }
        &-down {
            display: none;
        }
    }
  }