@import './styles/_mixins';

.App {
@include center;
max-width: 1200px;
margin: auto;
flex-direction: column;
transition: 1s;

h1 {
    font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

a {
    color: inherit;
}
}

// .dark {
//   @include darkTheme;
// }

::-webkit-scrollbar {
    width: 10px;
  }

/* Handle */
::-webkit-scrollbar-thumb {
    background: #424242; 
  }