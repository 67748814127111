@import '../styles/_mixins';

.ProjectRow2 {
    min-height: 100vh;
    @include center-vertically;

    &-group {
        margin-top: 2em;
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
    }

    @media only screen and (max-width: 700px) {
        .ProjectRow2-group {
            flex-direction: column;
        }
      }
}