.Game {
    margin-right: 1em;

    img {
        max-width: 100%;
        height: auto;
    }
    .static {
        position:absolute;
        background: white;
        transition: 0.5s;
        filter: grayscale(100%);
    
        &:hover {
            filter: grayscale(0%);
            opacity:0;
        }
      }
}

@media only screen and (max-width: 700px) {
    .Game {
        margin-bottom: 1.5em;
        margin-left: auto;
        margin-right: auto;
    }
  }
