@import '../styles/_mixins';

.Contact {
    @include center;
    text-align: center;
    flex-direction: column;
    min-height: 100vh;

    &-games {
        @include center;
    }
}

@media only screen and (max-width: 700px) {
    .Contact {
        &-games {
            flex-direction: column;
        }
    }
  }