@import '../styles/_mixins';

.ProjectRow1 {
    min-height: 100vh;
    @include center-vertically;

    &-projects {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
