@import '../styles/_mixins';
@import '../styles/_variables';

.Project {
    margin-bottom: 1em;
    @include center-vertically;
    flex-direction: row;
    color: $color-primary-dark;

    &-image {
        width: 50%;
        position: relative;
        filter: grayscale(100%);

        &:hover {
            z-index: 1;
            filter: grayscale(0%);
        }

        img {
            width: 100%;
            border-radius: 5%;
        }
    }


    &-detail {
        width: 50%;
        height: 50%;
        background-color: $color-primary;
        box-shadow: 6px 9px 5px 0px rgba(0,0,0,0.11)
        ;
        border-radius: 5%;
        margin-left: -5em;
        padding: 0.5em;
        position: relative;

        &-technology {
            display: flex;
            flex-wrap: wrap;
    
            &-item {
                border: 1px solid $color-primary-dark;
                background-color: $color-primary-dark;
                color: $color-primary;
                margin-bottom: 15px;
                margin-right: 5px;
                padding: 0.3em;
                transition: transform 250ms;
                cursor: default;
                @include hoverAnimation;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    
        &-buttons {
            display: flex;
            justify-content: flex-end;
        }
    }


}

.dark-project {
    color: $color-primary;
    

    .Project-detail {
        background-color: $color-primary-dark;
        box-shadow: 6px 9px 5px 0px rgba(0,0,0,0.25)
        ;

        &-technology-item {
            background-color: $color-primary;
            color: $color-primary-dark;
        }
    }
}

@media only screen and (max-width: 700px) {
    .Project {
        position: relative;
        &-image {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0.4;

            img {
                height: 100%
            }
        }

        &-detail {
            width: 100%;
            background-color: transparent;
            margin-left: 0;

            p {
            font-weight: bold;

            }
        }
    }

    .dark-project {
        .Project-detail {
            background-color: transparent;

            &-technology-item {
                border: none;
            }
        }
    }
}

