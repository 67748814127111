@import '../styles/_mixins';

.fa-4x {
    margin: 0.5rem;
    margin: 0 20px 30px;
    width: 60px;
    height: 60px;
    transition: transform 250ms;

    @include hoverAnimation;
}

@media only screen and (max-width: 700px) {
    .fa-4x {
        margin: 0.5rem;
    }
  }