@import '../styles/_mixins';
@import '../styles/_variables';

.ProjectSmall {
    width: 30%;
    transition: transform 250ms;

    &:hover {
        img {
            filter: grayscale(0%);
            opacity: 0.4;
        }
    }
    
    &-main {
        position: relative;

        &-info {
            width: 100%;
            font-weight: bold;
            color: $color-primary-dark;
            position: absolute;
            top: 25%;
            opacity: 0;

            h3, p {
                padding-left: 0.5rem;
            }

            h3 {
                margin-bottom: -0.5em;
            }
        }

        img {
            width: 100%;
            background-color: rgba(141, 153, 174, 1);
            position: relative;
            filter: grayscale(100%);
            transition: .2s ease;
            border-radius: 5%;
            box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.31);
        }


    }

    &-view {
        display: flex;
        justify-content: flex-end;

        p {
            margin-top: 3px;
        }
    }

    &:hover {
        .ProjectSmall-main-info{
            opacity: 1;
        }

        img {
            background-color: rgba(141, 153, 174, 0.5);
        }
    }

    @include hoverAnimation;
}

.dark-project-small {
    .ProjectSmall-main-info {
        color: $color-primary;
    }
}

@media only screen and (max-width: 830px) and (min-width: 701px) {

    .ProjectSmall {
        &-main-info {
            top: 5%;
        }
    }
}

@media only screen and (max-width: 700px) {
    .ProjectSmall {
        width: 100%;
        margin-top: 1rem;

        &-main-info{
            opacity: 1;
        }

        img {
            background-color: rgba(141, 153, 174, 0.5);
            opacity: 0.4;
        }
    }
  }
