@import '../styles/_mixins';

.About {
    @include center;
    min-height: 100vh;
    flex-direction: column;

    h2 {
        text-align: center;
        font-size: 3em;
    }

    p {
        margin: 0 auto;
        margin-bottom: 2em;
        width: 60%;
    }

    &-icons {

        margin: 0 auto;

        &-group-3 {
        @include center;
        }
    }
}

.__react_component_tooltip.show {
    opacity: 1 !important;
  }

@media only screen and (max-width: 700px) {
    .About {
        p {
            width: 80%;
        }
    }
    .About-icons {
        @include center;
        flex-direction: column;
    }
  }